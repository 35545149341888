import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import axios from 'axios';
import Home from './pages/Home';
import EventTypes from './pages/EventTypes';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import './App.css';
import settingsStore from './stores/SettingsStore';
import uiState from './stores/UiState';

@observer
class App extends Component<{}> {
  onSyncEvents = async () => {
    uiState.turnLoadingOn();
    await axios.post(
      'https://events-data-mgmt.metris.io/events/sync',
      {},
      { withCredentials: true },
    );
    uiState.turnLoadingOff();
    window.location.reload();
  };

  render() {
    return (
      <Router>
        <div>
          <nav
            className="navbar navbar-expand navbar-dark bg-dark"
            style={{ position: 'fixed', width: '100%', zIndex: 99 }}
          >
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a className="p-0 nav-link" href="/">
                  Home
                </a>
              </li>
            </ul>
            <ul className="navbar-nav">
              <li className="nav-item">
                {uiState.loading.general === true ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  false
                )}
              </li>
            </ul>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item mr-3">
                <button
                  type="button"
                  className={classNames(
                    'btn btn-sm',
                    {
                      'btn-outline-success': !settingsStore.settings
                        .showAdvanced,
                    },
                    { 'btn-success': settingsStore.settings.showAdvanced },
                  )}
                  onClick={() => settingsStore.toggle('showAdvanced')}
                >
                  {settingsStore.settings.showAdvanced === false ? (
                    <span>Show Advanced</span>
                  ) : (
                    <span>Hide Advanced</span>
                  )}
                </button>
              </li>
              <li className="nav-item">
                <button
                  type="button"
                  className="btn btn-sm btn-outline-info"
                  onClick={this.onSyncEvents}
                >
                  Sync Data
                </button>
              </li>
            </ul>
          </nav>
          <Route exact path="/" component={Home} />
          {/* via https://github.com/ReactTraining/react-router/issues/4105#issuecomment-291834881*/}
          <Route path="/event-types/:id?" component={EventTypes} />
        </div>
      </Router>
    );
  }
}

export default App;
