import { observable, autorun, toJS, set } from 'mobx';
import store from 'store';

type TSettingsStore = { [key: string]: boolean };

// via https://gist.github.com/du5rte/dbd18a1a6dc72d866737a5e95ca1e663
function autoSave(_this: any) {
  let firstRun = true;

  // will run on change
  autorun(() => {
    // on load check if there's an existing store on localStorage and extend the store
    if (firstRun) {
      const existingStore = store.get('settingsStore');

      if (
        existingStore &&
        JSON.stringify(Object.keys(existingStore)) ===
          JSON.stringify(Object.keys(_this))
      ) {
        set(_this, existingStore);
      }
    }

    // from then on serialize and save to localStorage
    store.set('settingsStore', toJS(_this));
  });

  firstRun = false;
}

class SettingsStore {
  @observable settings: TSettingsStore;

  constructor() {
    this.settings = {
      showAdvanced: false,
    };

    autoSave(this);
  }

  toggle(name: string): void {
    this.settings[name] = !this.settings[name];
  }
}

const settingsStore = new SettingsStore();

export default settingsStore;
