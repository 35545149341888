import { observable } from 'mobx';

class UiState {
  @observable loading: {
    general: boolean;
  };

  constructor() {
    this.loading = {
      general: false,
    };
  }

  turnLoadingOn() {
    this.loading.general = true;
  }

  turnLoadingOff() {
    this.loading.general = false;
  }
}

const uiState = new UiState();

export default uiState;
