import React, { Component } from 'react';
import axios from 'axios';
import { TEventType } from '../Types';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import './Home.css';
import settingsStore from '../stores/SettingsStore';
import { observer } from 'mobx-react';
import uiState from '../stores/UiState';
import classNames from 'classnames';

type TState = {
  event_types: TEventType[];
};

@observer
class Home extends Component<{}, TState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      event_types: [],
    };
  }

  componentDidMount() {
    this.refreshEventTypes();
  }

  refreshEventTypes = async () => {
    uiState.turnLoadingOn();
    const event_types: TEventType[] = await axios
      .get('https://events-data-mgmt.metris.io/event_types', {
        withCredentials: true,
      })
      .then(response => response.data.event_types);
    this.setState({
      event_types,
    });
    uiState.turnLoadingOff();
  };

  onToggleRun = (container_name: string, new_run: boolean) => async () => {
    uiState.turnLoadingOn();
    await axios.put(
      `https://events-data-mgmt.metris.io/containers/${container_name}`,
      {
        run: new_run,
      },
      { withCredentials: true },
    );
    uiState.turnLoadingOff();
    this.refreshEventTypes();
  };

  onTrainModel = (event_type_id: number) => async () => {
    uiState.turnLoadingOn();
    await axios.post(
      `https://events-data-mgmt.metris.io/events/train`,
      {
        event_type_id,
      },
      { withCredentials: true },
    );
    uiState.turnLoadingOff();
    this.refreshEventTypes();
  };

  onPredictModel = (event_type_id: number) => async () => {
    uiState.turnLoadingOn();
    await axios.post(
      `https://events-data-mgmt.metris.io/events/predict`,
      {
        event_type_id,
      },
      { withCredentials: true },
    );
    uiState.turnLoadingOff();
    this.refreshEventTypes();
  };

  render() {
    if (this.state.event_types.length === 0) {
      return (
        <div className="App container" style={{ padding: '66px' }}>
          <div className="text-muted">
            <small>
              If no data is shown, please first log in to{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://events-data-mgmt.metris.io"
              >
                https://events-data-mgmt.metris.io
              </a>{' '}
              and then refresh this page.
            </small>
          </div>
          <div className="text-center mt-3">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      );
    }

    const containers = ([] as {
      event_type: TEventType;
      classification: TEventType['containers'][0] | null;
      selectionSlotfilling: TEventType['containers'][0] | null;
    }[]).concat(
      ...this.state.event_types.map(event_type => {
        const classification = event_type.containers.find(
          container => container.task_type === 'classification',
        );
        const selectionSlotfilling = event_type.containers.find(
          container => container.task_type === 'selection-slotfilling',
        );

        return {
          event_type,
          classification: classification || null,
          selectionSlotfilling: selectionSlotfilling || null,
        };
      }),
    );

    return (
      <div className="content">
        <div className="App container">
          {settingsStore.settings['showAdvanced'] ? (
            <div>
              <h3>Docker Containers Status</h3>
              <table className="table table-bordered table-sm table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">event_type</th>
                    <th scope="col">evidence classification</th>
                    <th scope="col">attribute extraction</th>
                  </tr>
                </thead>
                <tbody>
                  {containers.map(
                    ({ event_type, classification, selectionSlotfilling }) => {
                      return (
                        <tr key={event_type.event_type_id}>
                          <td>
                            <a href={`#${event_type.event_type_id}`}>
                              #{event_type.event_type_id}
                            </a>
                          </td>
                          <td>{event_type.event_name}</td>
                          <td
                            className={classNames(
                              {
                                'table-success':
                                  classification && classification.run === true,
                              },
                              {
                                'table-warning':
                                  classification &&
                                  classification.run === false,
                              },
                            )}
                          >
                            {classification ? (
                              <React.Fragment>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`http://${
                                    classification.container_name
                                  }.metris.io`}
                                >
                                  {classification.container_name}
                                </a>{' '}
                                <button
                                  className="btn btn-sm btn-outline-secondary"
                                  onClick={this.onToggleRun(
                                    classification.container_name,
                                    !classification.run,
                                  )}
                                >
                                  toggle
                                </button>
                              </React.Fragment>
                            ) : null}
                          </td>
                          <td
                            className={classNames(
                              {
                                'table-success':
                                  selectionSlotfilling &&
                                  selectionSlotfilling.run === true,
                              },
                              {
                                'table-warning':
                                  selectionSlotfilling &&
                                  selectionSlotfilling.run === false,
                              },
                            )}
                          >
                            {selectionSlotfilling ? (
                              <React.Fragment>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`http://${
                                    selectionSlotfilling.container_name
                                  }.metris.io`}
                                >
                                  {selectionSlotfilling.container_name}
                                </a>{' '}
                                <button
                                  className="btn btn-sm btn-outline-secondary"
                                  onClick={this.onToggleRun(
                                    selectionSlotfilling.container_name,
                                    !selectionSlotfilling.run,
                                  )}
                                >
                                  toggle
                                </button>
                              </React.Fragment>
                            ) : null}
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </div>
          ) : null}
          <div>
            <h3>
              Existing event_types (<Link to="/event-types">new</Link>)
            </h3>
            <div>
              {this.state.event_types.map(event_type => {
                return (
                  <div
                    className="my-2"
                    key={event_type.event_type_id}
                    style={{ border: '1pt solid black', padding: '1.5rem' }}
                  >
                    <h4
                      title={format(
                        new Date(Math.round(event_type.created * 1000)),
                        'yyyy-MM-dd',
                      )}
                      id={event_type.event_type_id.toString()}
                    >
                      #{event_type.event_type_id}{' '}
                      <Link to={`/event-types/${event_type.event_type_id}`}>
                        {event_type.event_name}
                      </Link>
                    </h4>
                    <div>
                      {event_type.description.length > 0 ? (
                        <div>
                          <strong>event_description:</strong>{' '}
                          {event_type.description}
                        </div>
                      ) : null}
                      {event_type.annotation_instr &&
                      event_type.annotation_instr.length > 0 ? (
                        <div>
                          <strong>annotation_instr:</strong>{' '}
                          {event_type.annotation_instr}
                        </div>
                      ) : null}
                    </div>
                    {event_type.queries.length > 0 ? (
                      <div>
                        <strong>seed_queries:</strong>
                        <ul>
                          {event_type.queries.map(query => (
                            <li key={query.query_id}>
                              #{query.query_id}: <code>{query.query_dsl}</code>,{' '}
                              {query.query_description &&
                              query.query_description.length > 0 ? (
                                query.query_description
                              ) : (
                                <i>no description</i>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                    {event_type.slots.length > 0 ? (
                      <div>
                        <strong>Slots:</strong>
                        <ul>
                          {event_type.slots.map(slot => (
                            <li key={slot.slot_num}>
                              #{slot.slot_num}: {slot.slot_name},{' '}
                              {slot.slot_description &&
                              slot.slot_description.length > 0 ? (
                                slot.slot_description
                              ) : (
                                <i>no description</i>
                              )}
                              , <code>{JSON.stringify(slot.slot_types)}</code>
                              <ul>
                                {slot.slot_questions.map((question, curidx) => (
                                <li key={`${slot.slot_num}-question-${curidx}`}>
                                    #{curidx}{' '}<code>{question.question}</code>
                                </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                    <hr />
                    <div className="row">
                      <div className="col-6">
                        <strong>
                          Phase 1 (<i>evidence classification</i>):
                        </strong>
                        {event_type.containers.length > 0 ? (
                          <li>
                            docker:{' '}
                            {event_type.containers
                              .filter(
                                container =>
                                  container.task_type === 'classification',
                              )
                              .map(container => (
                                <span key={container.container_name}>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`http://${
                                      container.container_name
                                    }.metris.io`}
                                  >
                                    {container.container_name}
                                  </a>
                                  , run:{' '}
                                  <code>{JSON.stringify(container.run)}</code>{' '}
                                  <button
                                    className="btn btn-sm btn-outline-secondary"
                                    onClick={this.onToggleRun(
                                      container.container_name,
                                      !container.run,
                                    )}
                                  >
                                    toggle
                                  </button>
                                </span>
                              ))}
                          </li>
                        ) : null}
                        {settingsStore.settings.showAdvanced === true &&
                        event_type.stats ? (
                          <li>
                            stats:
                            <ul>
                              <li key="numbers">
                                <code>{event_type.stats.num_candidates}</code>{' '}
                                candidates,{' '}
                                <code>
                                  {event_type.stats.num_evidence_labelled}
                                </code>{' '}
                                candidates labeled
                              </li>
                              {event_type.stats.last_classif ? (
                                <li key="last_classif">
                                  classifier_evaluation (
                                  {format(
                                    new Date(
                                      Math.round(
                                        event_type.stats.last_classif.created *
                                          1000,
                                      ),
                                    ),
                                    'yyyy-MM-dd, HH:mm',
                                  )}
                                  )
                                  <ul>
                                    <li>
                                      accuracy:{' '}
                                      <code>
                                        {event_type.stats.last_classif.accuracy.toFixed(
                                          3,
                                        )}
                                      </code>
                                      , baseline:{' '}
                                      <code>
                                        {event_type.stats.last_classif.baseline.toFixed(
                                          3,
                                        )}
                                      </code>
                                    </li>
                                    <li>
                                      fscore:{' '}
                                      <code>
                                        {event_type.stats.last_classif.fscore.toFixed(
                                          3,
                                        )}
                                      </code>
                                      , precision:{' '}
                                      <code>
                                        {event_type.stats.last_classif.precision.toFixed(
                                          3,
                                        )}
                                      </code>
                                      , recall:{' '}
                                      <code>
                                        {event_type.stats.last_classif.recall.toFixed(
                                          3,
                                        )}
                                      </code>
                                    </li>
                                  </ul>
                                </li>
                              ) : null}
                            </ul>
                          </li>
                        ) : null}
                        <div>
                          <button
                            type="button"
                            className="btn btn-outline-info btn-sm mr-1"
                            onClick={this.onTrainModel(
                              event_type.event_type_id,
                            )}
                          >
                            Train Model
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-info btn-sm ml-1"
                            onClick={this.onPredictModel(
                              event_type.event_type_id,
                            )}
                          >
                            Generate Predictions
                          </button>
                        </div>
                      </div>
                      <div className="col-6">
                        <strong>
                          Phase 2 (<i>Slot filling</i>):
                        </strong>
                        {event_type.containers.length > 0 ? (
                          <li>
                            docker:{' '}
                            {event_type.containers
                              .filter(
                                container =>
                                  container.task_type ===
                                  'selection-slotfilling',
                              )
                              .map(container => (
                                <span key={container.container_name}>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`http://${
                                      container.container_name
                                    }.metris.io`}
                                  >
                                    {container.container_name}
                                  </a>
                                  , run:{' '}
                                  <code>{JSON.stringify(container.run)}</code>{' '}
                                  <button
                                    className="btn btn-sm btn-outline-secondary"
                                    onClick={this.onToggleRun(
                                      container.container_name,
                                      !container.run,
                                    )}
                                  >
                                    toggle
                                  </button>
                                </span>
                              ))}
                          </li>
                        ) : null}
                        {settingsStore.settings.showAdvanced === true &&
                        event_type.stats ? (
                          <li>
                            stats:
                            <ul>
                              <li key="numbers">
                                <code>
                                  {event_type.stats.num_slots_labelled}
                                </code>{' '}
                                slots labeled
                              </li>
                            </ul>
                          </li>
                        ) : null}
                        <div>
                          <button
                            type="button"
                            className="btn btn-outline-info btn-sm mr-1"
                            onClick={() => window.alert('Not implemented yet!')}
                          >
                            Train Model
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-info btn-sm ml-1"
                            onClick={() => window.alert('Not implemented yet!')}
                          >
                            Generate Predictions
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
