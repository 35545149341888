import React, { Component, FormEvent } from 'react';
import axios, { AxiosPromise } from 'axios';
import { TEventType, TEventTypeEdit } from '../Types';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observable, IObservableValue, IObservableArray } from 'mobx';

type TMatch = {
  id?: string;
};

const EMPTY_QUERY: TEventTypeEdit['queries'][0] = {
  query_dsl: '',
  ctx_window: 1,
  query_description: '',
};

const EMPTY_QUESTION: TEventTypeEdit['slots'][0]['slot_questions'][0] = {
  question_id: null,
  question: ''
};

function createEmptySlot(new_slot_id: number): TEventTypeEdit['slots'][0] {
  return {
    slot_num: new_slot_id,
    slot_name: '',
    slot_description: '',
    slot_types: [],
    slot_questions: [{question: 'hello?', question_id: 1}]
  };
}

const SLOT_TYPES = [
  'ORG',
  'QUANTITY',
  'CONCEPT',
  'LOC',
  'FAC',
  'NORP',
  'PERCENT',
  'PERSON',
  'GPE',
  'WORK_OF_ART',
  'MONEY',
  'LANGUAGE',
  'EVENT',
  'ORDINAL',
  'PRODUCT',
  'DATE',
  'CARDINAL',
  'TIME',
  'LAW',
];

@observer
class EventTypes extends Component<RouteComponentProps<TMatch>> {
  @observable
  event_type: TEventTypeEdit;
  query_results: IObservableArray<{
    doc_id: number;
    sent_num: number;
    sent_text_highlighted: string;
    sent_text: string;
  }>;
  loading_query_results: IObservableValue<boolean>;
  loading_event_type: IObservableValue<boolean>;
  show_query_results: IObservableValue<boolean>;
  stored_max_slot_num: IObservableValue<number>;

  constructor(props: RouteComponentProps<TMatch>) {
    super(props);

    // Creating a new event_type
    this.event_type = {
      event_name: '',
      description: '',
      annotation_instr: '',
      queries: [Object.assign({}, EMPTY_QUERY)],
      slots: [Object.assign({}, createEmptySlot(0))],
    };
    this.query_results = observable.array();
    this.loading_query_results = observable.box(false);
    this.loading_event_type = observable.box(false);
    this.show_query_results = observable.box(false);
    this.stored_max_slot_num = observable.box(
      this.event_type.slots[this.event_type.slots.length - 1].slot_num,
    );
  }

  async componentDidMount() {
    if (this.props.match.params.id) {
      // Editing an existing event_type
      const event_type: TEventType = await axios
        .get(
          `https://events-data-mgmt.metris.io/event_types/${
            this.props.match.params.id
          }`,
          { withCredentials: true },
        )
        .then(response => response.data.event_type);

      this.event_type = {
        event_name: event_type.event_name,
        description: event_type.description,
        annotation_instr: event_type.annotation_instr || '',
        queries: event_type.queries.map(query => {
          return {
            ...query,
            query_description: query.query_description || '',
          };
        }),
        slots: event_type.slots,
      };

      if (this.event_type.slots.length === 0) {
        this.stored_max_slot_num.set(-1);
      } else {
        this.stored_max_slot_num.set(
          this.event_type.slots[this.event_type.slots.length - 1].slot_num,
        );
      }

      return;
    }
  }

  onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    (this.event_type as any)[event.target.name] = event.target.value;
  };

  onQueryChange = (idx: number) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    (this.event_type.queries[idx] as any)[event.target.name] =
      event.target.value;
  };

  onQuestionAdd = (slot_num: number) => {
    this.event_type.slots[slot_num].slot_questions.push(Object.assign({}, EMPTY_QUESTION));
  };

  onQuestionRemove = (slot_idx: number, question_idx: number) => () => {
    this.event_type.slots[slot_idx].slot_questions.splice(question_idx, 1);
  };

  onQuestionChange = (slot_idx: number, question_idx: number) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    this.event_type.slots[slot_idx].slot_questions[question_idx].question = event.target.value;
  };

  onQueryAdd = () => {
    this.event_type.queries.push(Object.assign({}, EMPTY_QUERY));
  };

  onQueryRemove = (idx: number) => () => {
    this.event_type.queries.splice(idx, 1);
  };

  onSlotChange = (idx: number) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    (this.event_type.slots[idx] as any)[event.target.name] = event.target.value;
  };

  onSlotTypeChange = (idx: number) => (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    this.event_type.slots[idx].slot_types = [
      ...event.target.selectedOptions,
    ].map(option => option.label);
  };

  onSlotAdd = () => {
    const newEmptySlot = createEmptySlot(
      this.event_type.slots.length === 0
        ? 0
        : Math.max(
            this.stored_max_slot_num.get(),
            this.event_type.slots[this.event_type.slots.length - 1].slot_num,
          ) + 1,
    );
    this.event_type.slots.push(Object.assign({}, newEmptySlot));
  };

  onSlotRemove = (idx: number) => () => {
    this.event_type.slots.splice(idx, 1);
  };

  onEventTypeSave = async () => {
    this.loading_event_type.set(true);
    let request: AxiosPromise<any>;
    if (this.props.match.params.id) {
      // Update existing event type
      request = axios.put(
        `https://events-data-mgmt.metris.io/event_types/${
          this.props.match.params.id
        }`,
        {
          ...this.event_type,
          slots: this.event_type.slots,
        },
        { withCredentials: true },
      );
    } else {
      // Create new event type
      request = axios.post(
        'https://events-data-mgmt.metris.io/event_types',
        {
          ...this.event_type,
          slots: this.event_type.slots,
        },
        { withCredentials: true },
      );
    }

    try {
      const response = await request;
      console.log(response);
      // TODO: Did not work
      /*window.location.href = `${window.location.origin}/${
          response.data.event_type.event_type_id
        }`;*/
      window.location.href = window.location.origin;
    } catch (error) {
      console.log(error);
      window.alert(
        `${error.response.status}: ${JSON.stringify(
          error.response.data.error,
        )}`,
      );
    } finally {
      this.loading_event_type.set(false);
    }
  };

  onEventTypeDelete = async () => {
    if (this.props.match.params.id) {
      this.loading_event_type.set(true);
      try {
        await axios.delete(
          `https://events-data-mgmt.metris.io/event_types/${
            this.props.match.params.id
          }`,
          { withCredentials: true },
        );

        window.location.href = window.location.origin;
      } catch (error) {
        window.alert(
          `${error.response.status}: ${JSON.stringify(
            error.response.data.error,
          )}`,
        );
      }
    }
  };

  onSubmitQuerySearch = (idx: number) => (
    event: FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    this.onQuerySearch(idx);
  };

  onQuerySearch = async (idx: number) => {
    this.loading_query_results.set(true);
    const query_results: any = await axios
      .post(
        'https://events-data-mgmt.metris.io/queries/preview',
        { query_dsl: this.event_type.queries[idx].query_dsl },
        { withCredentials: true },
      )
      .then(response => response.data.data);
    this.query_results.replace(query_results);
    this.show_query_results.set(true);
    this.loading_query_results.set(false);
  };

  render() {
    const event_type = this.event_type;

    return (
      <div className="App container" style={{ padding: '66px' }}>
        <div className="form-group">
          <input
            className="form-control"
            type="text"
            placeholder="Name"
            value={event_type.event_name}
            name="event_name"
            onChange={this.onChange}
          />
        </div>
        <div className="form-group">
          <textarea
            className="form-control"
            placeholder="Description"
            value={event_type.description}
            name="description"
            rows={2}
            onChange={this.onChange}
          />
        </div>
        <textarea
          className="form-control"
          placeholder="Annotation Instructions"
          value={event_type.annotation_instr}
          name="annotation_instr"
          rows={1}
          onChange={this.onChange}
        />
        <div className="my-3">
          <h5>queries</h5>
          {event_type.queries.map((query, idx) => {
            return (
              <div key={idx} className="bg-light shadow-sm p-3 my-2">
                <form onSubmit={this.onSubmitQuerySearch(idx)}>
                  <div className="form-group input-group">
                    <textarea
                      className="form-control"
                      placeholder="DSL"
                      value={query.query_dsl}
                      name="query_dsl"
                      onChange={this.onQueryChange(idx)}
                      onKeyPress={event => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          this.onQuerySearch(idx);
                        }
                      }}
                      rows={1}
                    />
                    <div className="input-group-append">
                      <input
                        type="submit"
                        className="btn btn-sm btn-outline-info"
                        value="Preview"
                        title="(Press ENTER while editing query)"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      placeholder="Description"
                      value={query.query_description}
                      name="query_description"
                      onChange={this.onQueryChange(idx)}
                      rows={1}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Window"
                      value={query.ctx_window}
                      name="ctx_window"
                      onChange={this.onQueryChange(idx)}
                    />
                  </div>
                  <input
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                    value="Remove"
                    onClick={this.onQueryRemove(idx)}
                  />
                </form>
              </div>
            );
          })}
          <input
            type="button"
            className="mt-2 btn btn-sm btn-outline-secondary"
            value="Add one more query"
            onClick={this.onQueryAdd}
          />
        </div>
        {this.loading_query_results.get() === true ? <div>Loading</div> : null}
        {this.query_results.length > 0 &&
        this.show_query_results.get() === true ? (
          <div className="mt-3">
            <table className="table table-bordered table-sm">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">
                    sent_text
                    <button
                      className="ml-3 btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        this.show_query_results.set(false);
                      }}
                    >
                      Close
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.query_results.map(result => {
                  return (
                    <tr key={`${result.doc_id}.${result.sent_num}`}>
                      <td
                        title={`{doc_id: ${result.doc_id}, sent_num: ${
                          result.sent_num
                        }}`}
                      >
                        {result.sent_text}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : null}
        <div className="my-3">
          <h5>slots</h5>
          {event_type.slots.map((slot, idx) => {
            return (
              <div key={idx} className="bg-light shadow-sm p-3 my-2">
                #{slot.slot_num}
                <div className="row mt-2">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        value={slot.slot_name}
                        name="slot_name"
                        onChange={this.onSlotChange(idx)}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Description"
                        value={slot.slot_description}
                        name="slot_description"
                        onChange={this.onSlotChange(idx)}
                      />
                    </div>

                  Questions: {
                    slot.slot_questions.map( (slot_question, question_idx) => {
                            return (
                                <div className="form-group">
                                  <div className="row">
                                  <div className="col-md-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Question"
                                        value={slot_question.question}
                                        name={`slot_question_${question_idx}`}
                                        onChange={this.onQuestionChange(idx, question_idx)}
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <input
                                      type="button"
                                      className="btn btn-sm btn-outline-secondary"
                                      value="Remove"
                                      onClick={this.onQuestionRemove(idx, question_idx)}
                                    />
                                  </div>
                                  </div>
                                </div>
                            );
                        }
                    )
                  }
                  <div className="form-group">
                    <input
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      value="Add new question"
                      onClick={() => {this.onQuestionAdd(idx);}}
                    />
                  </div>
                    </div>
                  <div className="col-md-6 form-group">
                    <select
                      multiple
                      name="slot_types"
                      className="form-control"
                      value={slot.slot_types}
                      onChange={this.onSlotTypeChange(idx)}
                    >
                      {SLOT_TYPES.map(slot_type => {
                        return (
                          <option key={slot_type} value={slot_type}>
                            {slot_type}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <input
                  type="button"
                  className="btn btn-sm btn-outline-secondary"
                  value="Remove slot"
                  onClick={this.onSlotRemove(idx)}
                />
              </div>
            );
          })}
          <input
            type="button"
            className="mt-2 btn btn-sm btn-outline-secondary"
            value="Add one more slot"
            onClick={this.onSlotAdd}
          />
        </div>
        <div className="my-3">
          <h5>actions</h5>
          <input
            className="btn btn-outline-success"
            type="button"
            value="Save"
            onClick={this.onEventTypeSave}
          />
          {this.props.match.params.id ? (
            <input
              type="button"
              className="btn btn-outline-danger"
              value="Delete"
              onClick={this.onEventTypeDelete}
            />
          ) : null}
        </div>
        {this.loading_event_type.get() === true ? (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : null}
      </div>
    );
  }
}

export default EventTypes;
